import { FunctionComponent, HTMLAttributes } from 'react';

const IconPlus: FunctionComponent<
  HTMLAttributes<HTMLOrSVGElement> & { isMinus: boolean }
> = ({ isMinus = false, ...props }) => (
  <svg
    {...props}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path
      d={!isMinus ? 'M17 9H1M9 1V17V1Z' : 'M17 9H1M9 9V9V9Z'}
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="transition-all duration-300"
    />
  </svg>
);

export default IconPlus;
