import cx from 'classnames';
import { atom, useAtom } from 'jotai';
import { Dispatch, FunctionComponent, SetStateAction, useRef } from 'react';

import TextInput from '../../../ui/v2/inputs/text';

type TSearchInput = {
  search?: string;
  setSearch?: Dispatch<SetStateAction<string | undefined>>;
  placeholder: string;
  isFullWith?: boolean;
  targetId?: string | undefined;
};

const searchInputAtom = atom('');

const SearchInput: FunctionComponent<TSearchInput> = ({
  search = '',
  setSearch,
  placeholder,
  isFullWith = false,
  targetId,
}) => {
  const [, setsearchAtom] = useAtom(searchInputAtom);

  return (
    <div className={cx('flex w-full', { 'lg:w-1/2': !isFullWith }, 'mx-auto')}>
      <TextInput
        value={search}
        onChange={(e) => {
          if (setSearch) {
            setSearch(e.currentTarget.value);
          }
          setsearchAtom(e.currentTarget.value);
          document
            .getElementById(targetId || 'scroll_to_search')
            ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }}
        label={placeholder}
        labelBehaviour="animated"
        variant="filter"
        border
        clearButton={true}
        onClear={() => {
          if (setSearch) {
            setSearch(undefined);
          }
        }}
      />
    </div>
  );
};

export default SearchInput;
