import ValuePropositionClient from './index.client';
import { useAppContext } from '../../providers/app-context';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent, ReactNode } from 'react';

type TMessage = {
  variant: any;
  alt: string;
  title: ReactNode;
  text: ReactNode;
};

const ValueProposition: FunctionComponent<{
  variant?: Pick<TMessage, 'variant'>;
  infoToDisplayInTitle?: string;
  isHome?: boolean;
}> = ({ variant = 'PRIMARY', infoToDisplayInTitle = '', isHome = false }) => {
  const { t } = useTranslation();
  const { businessValues } = useAppContext();
  const messages = t(
    'value-proposition:messages',
    { totalStockAvailable: businessValues.totalStockAvailable.defaultValue },
    {
      returnObjects: true,
    }
  );
  return (
    <ValuePropositionClient
      values={messages.map((e) => ({
        ...e,
        alt: t(e.alt),
        title: t(e.title),
        text: t(e.text),
        plusAlt: t(e.plusAlt),
      }))}
      variant={variant}
      infoToDisplayInTitle={infoToDisplayInTitle}
      isHome={isHome}
    />
  );
};

export default ValueProposition;
