'use client';
import cx from 'classnames';
import { Fragment, ReactNode, useState } from 'react';
import ProtectSvg from '../../ui/support-banner/protect';
import ShippingSvg from '../../ui/support-banner/shipping';
import PaymentSvg from '../../ui/support-banner/payment';
import StockSvg from '../../ui/support-banner/stock';
import IconPlus from '../../ui/icons/plus';
import useTranslation from 'next-translate/useTranslation';

type ValueItem = {
  variant: TVariants;
  key: string;
  img: any;
  alt: string;
  title: string;
  text: ReactNode | string;
  plusAlt: string;
};

type ValuePropositionClientProps = {
  values: ValueItem[];
  variant?: any;
  infoToDisplayInTitle?: string;
  isHome?: boolean;
};

type TVariants = 'PROTECT' | 'SHIPPING' | 'PAYMENT' | 'STOCK';

const returnIcon = (type: TVariants, className: string, alt: string = '') => {
  switch (type) {
    case 'PROTECT':
      return <ProtectSvg title={alt} className={className} />;
    case 'SHIPPING':
      return <ShippingSvg title={alt} className={className} />;
    case 'PAYMENT':
      return <PaymentSvg title={alt} className={className} />;
    case 'STOCK':
      return <StockSvg title={alt} className={className} />;

    default:
      return null;
  }
};

const ValuePropositionClient = ({
  values,
  variant,
  isHome,
  infoToDisplayInTitle,
}: ValuePropositionClientProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<ValueItem['key'] | null>(null);

  return (
    <section
      className={cx(
        'py-3 lg:py-12 px-2 lg:px-6',
        variant === 'SECONDARY' && 'bg-neutral-10'
      )}
    >
      {!isHome && (
        <span
          className={cx(
            'container mx-auto',
            'block',
            'font-bold',
            'max-lg:px-8',
            'text-[22px]',
            'lg:text-[32px]',
            'text-center',
            'mb-6'
          )}
        >
          {t('value-proposition:title', {
            info: infoToDisplayInTitle,
          })}
        </span>
      )}
      <dl
        className={cx(
          'container mx-auto',
          // grid mobile
          'max-lg:flex max-lg:flex-col',
          // grid desktop
          'lg:grid lg:grid-cols-3 lg:grid-rows-2 lg:grid-flow-col lg:gap-x-6 lg:gap-y-3'
        )}
      >
        {values.map(({ variant, alt, title, text }) => (
          <Fragment key={variant}>
            <dt>
              <button
                onClick={() =>
                  open === variant ? setOpen(null) : setOpen(variant)
                }
                className={cx(
                  'lg:cursor-default',
                  'w-full py-[10px] max-lg:px-4',
                  'text-left text-xl lg:text-2xl',
                  'flex items-center gap-4 lg:gap-6 font-bold'
                )}
              >
                {returnIcon(variant, `m-auto w-6 lg:w-8 h-6 lg:h-8`, alt)}
                <span className="grow">{title}</span>
                <IconPlus
                  isMinus={false}
                  className={cx(
                    'w-4 h-4 lg:hidden transition-transform transform',
                    {
                      'rotate-45': open === variant,
                    }
                  )}
                ></IconPlus>
              </button>
            </dt>
            <dd
              className={cx(
                'font-light',
                'pl-14 max-lg:pr-4',
                'max-lg:grid max-lg:grid-rows-[0fr] max-lg:transition-[grid-template-rows] max-lg:duration-300 aria-expanded:grid-rows-[1fr]'
              )}
              aria-expanded={open === variant}
            >
              <span className="overflow-hidden text-pretty">{text}</span>
            </dd>
          </Fragment>
        ))}
      </dl>
    </section>
  );
};

export default ValuePropositionClient;
