import { FunctionComponent, HTMLAttributes } from 'react';

const FilterIcon: FunctionComponent<HTMLAttributes<HTMLOrSVGElement>> = (
  props
) => (
  <svg
    {...props}
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path
      d="M1.14263 1.8258L7.16643 8.8422C7.25651 8.94564 7.30656 9.07793 7.30752 9.21509V13.4769C7.30693 13.5366 7.32524 13.595 7.35981 13.6437C7.39439 13.6924 7.44347 13.7289 7.50004 13.748L10.3628 14.7006C10.4056 14.7149 10.4512 14.7189 10.4958 14.712C10.5404 14.7052 10.5827 14.6878 10.6192 14.6613C10.6558 14.6349 10.6855 14.6 10.7059 14.5598C10.7262 14.5195 10.7367 14.475 10.7364 14.4299V9.21509C10.7379 9.0777 10.7886 8.94539 10.8793 8.8422L16.9013 1.8258C16.9636 1.75326 17.0038 1.66439 17.0171 1.56973C17.0304 1.47506 17.0163 1.37855 16.9765 1.29165C16.9366 1.20474 16.8727 1.13108 16.7923 1.07939C16.7119 1.0277 16.6183 1.00015 16.5227 1H1.52302C1.42727 0.999809 1.33348 1.02711 1.25279 1.07867C1.1721 1.13023 1.1079 1.20387 1.06784 1.29084C1.02778 1.37781 1.01353 1.47445 1.02679 1.56928C1.04005 1.66411 1.08025 1.75315 1.14263 1.8258Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FilterIcon;
