import cx from 'classnames';
import {
  forwardRef,
  ComponentProps,
  ReactNode,
  useRef,
  useState,
  ChangeEvent,
  FocusEvent,
} from 'react';

import { useDisableFocus } from '../../../../providers/disable-focus';
import Button from '../../../button';
import Cheverlon from '../../../icons/cheverlon';
import FilterIcon from '../../../icons/filter';
import BPImage from '../../../image';
import { ErrorLine } from '../errors';

import classes from './text.module.css';

type TextInputProps = {
  as?: 'input' | 'textarea';
  hideLabel?: boolean;
  label: string;
  labelBehaviour?: 'default' | 'animated';
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  variant?: 'filter' | 'select' | 'search' | 'default';
  border?: boolean;
  error_messages?: Array<string>;
  inputExtraCss?: string;
  clearButton?: boolean;
  onClear?: () => void;
  focusBg?: boolean;
} & ComponentProps<'input'>;

const getIcon = (variant: TextInputProps['variant'], styles?: string) => {
  switch (variant) {
    case 'search':
      return <FilterIcon className={styles} />;
    case 'filter':
      return <FilterIcon className={styles} />;
    case 'select':
      return <Cheverlon className={styles} />;

    default:
      break;
  }
};

const getVariantCSS = (variant: TextInputProps['labelBehaviour']) => {
  switch (variant) {
    case 'animated':
      return cx(classes.animatedLabel, 'absolute', 'text-neutral-80');
    case 'default':
    default:
      return cx('px-4', 'text-xs', 'font-bold');
  }
};

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      as: As = 'input',
      hideLabel = false,
      labelBehaviour = 'default',
      id,
      label,
      leftIcon,
      rightIcon,
      variant = 'default',
      border = false,
      error_messages = [],
      onChange: onChangeCallback,
      onFocus: onFocusCallback,
      onBlur: onBlurCallback,
      placeholder: originalPlaceholder,
      inputExtraCss,
      clearButton,
      onClear,
      focusBg = false,
      ...props
    },
    ref
  ) => {
    const disableFocus = useDisableFocus();
    const innerRef = useRef<HTMLInputElement>(null);
    const labelRef = useRef<HTMLLabelElement>(null);
    const [showErrors, setShowErrors] = useState<boolean>(
      !!error_messages.length
    );
    const [isInputWithText, setIsInputWithText] = useState<boolean>(false);
    const targerRef = ref || innerRef;

    const onClearButton = () => {
      setIsInputWithText(false);
      if (onClear) {
        onClear();
      }
    };

    const customOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      setIsInputWithText(true);
      if (targerRef.current?.value === '') {
        setIsInputWithText(false);
      }
      if (error_messages.length) setShowErrors(false);

      if (onChangeCallback) {
        onChangeCallback(e);
      }
    };

    const customOnFocus = (e: FocusEvent<HTMLInputElement>) => {
      targerRef.current?.focus();
      labelRef.current?.classList.add(classes.focus);

      if (onFocusCallback) {
        onFocusCallback(e);
      }
    };

    const customOnBlur = (e: FocusEvent<HTMLInputElement>) => {
      if (!targerRef.current?.value && !props.value) {
        setIsInputWithText(false);
        labelRef.current?.classList.remove(classes.focus);
      }

      if (onBlurCallback) {
        onBlurCallback(e);
      }
    };

    return (
      <div className={cx('group', 'relative', 'w-full')}>
        {label && !hideLabel && (
          <label
            ref={labelRef}
            htmlFor={id}
            className={cx(
              'block my-[5px]',
              'cursor-text',
              { 'group-focus-within:text-brand-100': focusBg },
              { [classes.focus]: !!props.value },
              getVariantCSS(labelBehaviour)
            )}
            onClick={() => targerRef.current?.focus()}
          >
            {label}
          </label>
        )}
        <div
          className={cx(
            { flex: !clearButton },
            {
              'w-full grid grid-cols-[1fr_48px]':
                clearButton && variant === 'default',
            },
            {
              'w-full grid grid-cols-[1fr_48px]':
                clearButton && variant !== 'default',
            },
            ...(border
              ? [
                  'border-b',
                  showErrors && error_messages.length
                    ? 'border-red-100'
                    : 'border-neutral-80 focus:border-brand-100',
                ]
              : []),
            { 'group-focus-within:bg-brand-10': focusBg },
            'grow',
            classes.container
          )}
          onClick={() => {
            // TODO - Property 'current' does not exist on type '(instance: HTMLInputElement | null) => void'
            targerRef.current?.focus();
          }}
        >
          <As
            className={cx(
              'flex',
              'text-base',
              'leading-5',
              As === 'input' && 'h-12',
              'min-w-full',
              'px-4',
              { 'pb-2 pt-5': labelBehaviour === 'animated' },
              { 'pb-3 pt-4': labelBehaviour !== 'animated' },
              'outline-none',
              '',
              'bg-transparent',
              'disabled:bg-neutral-10',
              'disabled:text-neutral-50',
              'rounded-none',
              { 'placeholder-red-100': showErrors && error_messages.length },
              {
                'focus:placeholder-brand-100':
                  !showErrors || !error_messages.length,
              },
              inputExtraCss
            )}
            id={id}
            //tabIndex={disableFocus ? -1 : 0}
            ref={targerRef}
            aria-label={label}
            {...props}
            onChange={customOnChange}
            onFocus={customOnFocus}
            onBlur={customOnBlur}
            placeholder={
              originalPlaceholder || (hideLabel && label) || undefined
            }
          />
          {clearButton && (
            <Button
              type="button"
              onClick={onClearButton}
              className={cx('pt-[5px] ', {
                hidden: !isInputWithText,
              })}
            >
              <BPImage
                src={`${process.env.PUBLIC_PREFIX}/svg/icons/delete.svg`}
                width={16}
                height={16}
                className={cx('h-4', 'w-4')}
              />
            </Button>
          )}
          <div
            className={cx({
              hidden: isInputWithText,
            })}
          >
            {getIcon(variant, cx('h-4', 'w-5', 'z-[-1]'))}
          </div>
        </div>
        {showErrors && error_messages && (
          <div className={cx('py-2')}>
            {error_messages.map((m, k) => (
              <ErrorLine key={`${m}-${k}`} message={m} />
            ))}
          </div>
        )}
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';

export default TextInput;
