import cx from 'classnames';
import { FunctionComponent } from 'react';

type TErrorLine = {
  message: string;
};

export const ErrorLine: FunctionComponent<TErrorLine> = ({ message }) => (
    <small
      className={cx('block', 'text-red-100', 'text-xs', 'leading-4')}
    >
      {message}
    </small>
  );
